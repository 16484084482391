<template>
	<div>
		<Header title="年卡详情" @back="onBack"></Header>
		<a-spin :spinning="loading">
		<div>
			<a-row style="margin: 30px 0;">
				<a-col :span="3">
					<img style="width: 100%;margin: 0px 0 0 10px;max-width: 180px;" src="../../../../assets/image/gradeCard.png"/>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>影院名称：<span>{{ detail.cinemaName }}</span></p>
					<p>购票次数：<span>{{ detail.usedCount || 0 }}</span></p>
					<!-- <p>会员昵称：<span>{{ detail.memberCode }}</span></p> -->
					<p>开卡时间：<span>{{ transDateTime(detail.createTime) }}</span></p>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>年卡卡号：<span>{{ detail.cardNumber }}</span></p>
					<p>年卡等级：<span>{{ detail.cardTitle }}</span></p>
					<p>会员手机：<span>{{ detail.memberPhone }}</span></p>
					<!-- <p>违规类型：<span>{{ detail.violationType === 1 ? '信用值' : '-' }}</span></p> -->
				</a-col>
				<a-col :offset="1" :span="6">
					<p>开卡金额：<span>{{ detail.orderMoney }}</span></p>
					<p>有效期：<span>{{ detail.isNeverExpire ? '永不过期' : transDateTime(detail.expireDate, 1) }}</span></p>
					<p>年卡状态：<span>{{ ['正常', '过期', '手动停用', '信用停用', '启用审核中', '未认证', '退卡', '已回收'][detail.status - 1] }}</span></p>
				</a-col>
			</a-row>
			
			 <a-tabs type="card">
				<a-tab-pane key="1" tab="消费记录">
					<a-table :columns="columns" :dataSource="list" :pagination="pagination" :scroll="{ x: 1800 }">
						<template #bodyCell="{column, record}">
							<template v-if="column.key === 'userInfo'">
								<div>{{ record.nickname }}</div>
								<div>{{ record.phone }}</div>
							</template>
							<template v-if="column.key === 'ticketStatus'">
								<div v-if="record.status !== 'AFTERSALE_FINISH'">{{ ['未取票', '出票中', '已取票'][record.ticketStatus - 1] }}</div>
								<div v-else>
									<div>已退票</div>
									<div>退票时间：{{ transDateTime(record.refundTime) }}</div>
								</div>
							</template>
							<template v-if="column.key === 'createTime'">
								{{ transDateTime(record.createTime) }}
							</template>
							<template v-if="column.key === 'showTime'">
								<div>{{ transDateTime(record.showStartTime) }}</div>
								至
								<div>{{ transDateTime(record.showEndTime) }}</div>
							</template>
						</template>
					</a-table>
				</a-tab-pane>
			 </a-tabs>
			 
			 <div style="margin: 30px 0;text-align: center;">
				 <a-button @click="onBack">返回</a-button>
			 </div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getCardInfoDetail, getCardSaleList } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data(){
			return {
				loading: false,
				detail: {},
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex:'cinemaName'
				}, {
					title: '订单号',
					dataIndex: 'orderNo'
				},{
					title: '会员信息',
					key: 'userInfo'
				},{
					title: '影片名称',
					dataIndex:'filmName'
				}, {
					title:'影厅名称',
					dataIndex: 'hallName'
				},{
					title:'场次时间',
					dataIndex: 'duration'
				},{
					title:'实际付款金额',
					dataIndex: 'payment'
				},{
					title:'购买票数',
					dataIndex: 'seatNum'
				}, {
					title: '取票状态',
					key: 'ticketStatus'
				},{
					title:'支付时间',
					key: 'createTime'
				},{
					title: '放映起始/结束时间',
					// width: 160,
					key: 'showTime'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getCardSaleList();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getCardSaleList();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack() {
				this.$emit('back', false);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardInfoDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.detail = ret.data;
						this.getCardSaleList();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getCardSaleList(){
				this.loading = true;
				try {
					let ret = await getCardSaleList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						organizationId: this.detail.organizationId,
						cinemaId: this.detail.cinemaId,
						couponType: 2,
						cardNumber: this.detail.cardNumber
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style scoped>
	p > span {
		color: rgba(0,0,0,.65);
	}
</style>
