<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;" @change="getCardPolicyList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row v-show="showAll">
					<a-form-item label="年卡卡号" name="cardNumber" class="ui-form__item" >
						<a-input v-model:value="formState.cardNumber" placeholder="请输入年卡卡号"/>
					</a-form-item>
					
					<a-form-item label="会员昵称" name="memberName" class="ui-form__item" >
						<a-input v-model:value="formState.memberName" placeholder="请输入会员昵称"/>
					</a-form-item>
					
					<a-form-item label="会员手机" name="memberPhone" class="ui-form__item" >
						<a-input v-model:value="formState.memberPhone" placeholder="请输入会员手机"/>
					</a-form-item>
					
					<a-form-item label="年卡等级" name="cardId" class="ui-form__item" >
						<a-select v-model:value="formState.cardId" style="width: 180px;" placeholder="请选择年卡等级">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cardAllList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="年卡状态" name="status" class="ui-form__item" >
						<a-select v-model:value="formState.status" style="width: 180px;" placeholder="请选择年卡状态">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">正常</a-select-option>
							<a-select-option :value="2">过期</a-select-option>
							<a-select-option :value="3">手动停用</a-select-option>
							<a-select-option :value="7" v-if="!pageType">退卡</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="是否永不过期" name="isNeverExpire" class="ui-form__item">
						<a-select style="width: 180px;" placeholder="请选择" v-model:value="formState.isNeverExpire">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
						<!-- <a-switch v-model:checked="formState.isNeverExpire" checked-children="是" un-checked-children="否"></a-switch> -->
					</a-form-item>
					
					<a-form-item v-if="!formState.isNeverExpire" class="ui-form__item" label="有效时间">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="[pageType ? 'coupon_yearCard_detailInfo_delay' : 'coupon_yearCard_info_delay']" type="primary" style="margin-right: 20px;" :disabled="selectedRowKeys.length === 0" @click="onBatchDelay">批量延期</a-button>
						<!-- <a-button type="primary" @click="onAdd">添加</a-button> -->
						<span v-permission="[pageType ? 'coupon_yearCard_detailInfo_export' : 'coupon_yearCard_info_export']">
							<!-- 导出 -->
							<exportComponent type="baseYearCard" :searchData="searchData"></exportComponent>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, getCheckboxProps: getCheckboxProps }" :scroll="{ x: 2400 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.memberName || '-' }}</div>
							<div>会员手机：{{ record.memberPhone || '-' }}</div>
						</template>
						<template v-else-if="column.key === 'status'">
							<div style="color: #fa8c16;">{{ ['正常', '过期', '手动停用', '信用停用', '启用审核中', '未认证', '退卡', '已回收'][record.status - 1] }}</div>
							<div v-if="record.status === 7">
								备注：{{ record.refundCardRemark || '-' }}
							</div>
						</template>
						<template v-else-if="column.key === 'expireDate'">
							<div v-if="record.isNeverExpire === 1">永久有效</div>
							<div v-else>{{ transDateTime(record.expireDate, 1) }}</div>
						</template>
						<template v-else-if="column.key === 'createTime'">
							<div>{{ transDateTime(record.createTime) }}</div>
						</template>
						<template v-else-if="column.key === 'form'">
							{{ record.isImport ? '导入' : '订单' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="[pageType ? 'coupon_yearCard_detailInfo_detail' : 'coupon_yearCard_info_detail']" @click="onDeatil(record)">
											<a-menu-item>
												详情
											</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_yearCard_detailInfo_delay' : 'coupon_yearCard_info_delay']" v-if="record.status !== 7 && record.isNeverExpire !== 1" @click="onDelay(record)">
											<a-menu-item>
												延期
											</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_yearCard_detailInfo_disabled' : 'coupon_yearCard_info_disabled']" v-if="record.status !== 7 && record.isDisabled === 0" @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_yearCard_detailInfo_open' : 'coupon_yearCard_info_open']" v-else-if="record.status !== 7" @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="[pageType ? 'coupon_yearCard_detailInfo_refund' : 'coupon_yearCard_info_refund']" v-if="record.status !== 7" @click="onRefund(record)">
											<a-menu-item>
												退卡
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="refundVisible" title="退卡" @cancel="onRefundCancel" @ok="onRefundOk">
				<a-spin :spinning="loading">
					<a-form ref="refundForm" name="refundForm" :model="refundData">
						<a-form-item label="退卡备注" name="remark" :rules="[{required: true, message: '请退卡的备注和金额'}]">
							<a-textarea v-model:value="refundData.remark" placeholder="请输入退卡的备注和金额"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
			
			<a-modal v-model:visible="visible" destroyOnClose title="已选" width="850px" @ok="onBatchDelayOk" @cancel="onBatchCancel">
				<a-button style="margin-bottom: 20px;" @click="setVisible = true">设置延期时间</a-button>
				
				<a-table :dataSource="selectedListData" :columns="selectedColumns" :pagination="false">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'expireDate'">
							<div v-if="record.isNeverExpire === 1">永久有效</div>
							<div v-else>{{ transDateTime(record.expireDate, 1) }}</div>
						</template>
						<template v-else-if="column.key === 'newExpireDate'">
							<div v-if="record.isNeverExpire === 1">永久有效</div>
							<span v-if="delayModelRef.setValue && !setVisible">{{ transDateTime(delayEndTime(record.expireDate), 1) }}</span>
						</template>
					</template>
				</a-table>
			</a-modal>
			
			<a-modal v-model:visible="setVisible" destroyOnClose width="800px" title="年卡延期" @ok="onDelayOk" @cancel="onDelayCancel">
				<a-spin :spinning="loading">
					<a-form ref="delayForm" name="delayForm" :model="delayModelRef" :labelCol="{span: 5}" :wrapperCol="{span: 16}">
						<div v-if="!visible">
							<a-form-item label="会员手机号">{{ setRecordData.memberPhone }}</a-form-item>
							<a-form-item label="年卡卡号">{{ setRecordData.cardNumber }}</a-form-item>
							<a-form-item label="有效时间">{{ transDateTime(setRecordData.expireDate, 1) }}</a-form-item>
						</div>
						
						<a-form-item label="快捷选择" name="setValue" :rules="[{required: true, message: '必选项不允许为空'}]">
							<a-radio-group v-model:value="delayModelRef.setValue" button-style="solid">
								<a-radio-button :value="1">1天</a-radio-button>
								<a-radio-button :value="2">7天</a-radio-button>
								<a-radio-button :value="3">3月</a-radio-button>
								<a-radio-button :value="4">6月</a-radio-button>
								<a-radio-button :value="5">1年</a-radio-button>
								<a-radio-button :value="6">自定义日期</a-radio-button>
								<a-radio-button :value="7">自定义时间</a-radio-button>
							</a-radio-group>
						</a-form-item>
						
						<div>
							<a-form-item v-if="delayModelRef.setValue === 6" label="延期至" name="specifiesTime" :rules="[{required: true, message: '必选项不允许为空'}]">
								<a-date-picker :disabled-date="disabledDate" v-model:value="delayModelRef.specifiesTime" />
								<p style="margin-top: 6px;color: #666;">需大于今天才会生效</p>
							</a-form-item>
							<a-form-item v-else-if="delayModelRef.setValue === 7" label="延期至" name="delayDays" :rules="[{required: true, message: '必选项不允许为空'}]">
								<a-input-number v-model:value="delayModelRef.delayDays" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span style="padding-left: 8px;">天</span>
							</a-form-item>
							<a-form-item v-else-if="!visible" label="延期至">
								<span>{{ transDateTime(delayEndTime(setRecordData.expireDate), 1) }}</span>
							</a-form-item>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
	<detail v-if="showModal" :id="id" @back="onBack"></detail>
</template>

<script>
	import detail from './detail.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getCardPolicyList, getCardInfoList, pullOnShelvesCardInfo, pullOffShelvesCardInfo, delayCardInfo, batchDelayCardInfo, cancelCard} from "@/service/modules/coupon.js";
	export default {
		components: {
			Icon,
			detail,
			exportComponent
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				cardAllList: [],
				formState: {
					type: 2,
					// organizationId: 0,
					cinemaId: 0,
					cardId: 0,
					status: 0,
					isNeverExpire: -1
				},
				searchData: {},
				time: [],
				setRecordData: {},
				delayModelRef: {},
				visible: false,
				setVisible: false,
				showModal: false,
				setValue: undefined,
				selectedRowKeys: [],
				selectedListData: [],
				selectedColumns: [{
					title: '影院',
					dataIndex: 'cinemaName'
				}, {
					title: '卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '延期前有效期',
					key: 'expireDate'
				}, {
					title: '延期后有效期',
					key: 'newExpireDate'
				}],
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '开卡金额',
					dataIndex: 'orderMoney',
					width: 130
				}, {
					title: '购票次数',
					dataIndex: 'usedCount',
					width: 130
				},{
					title: '卡等级',
					dataIndex: 'cardTitle'
				},{
					title: '会员信息',
					key: 'userInfo'
				},{
					title: '卡状态',
					key: 'status',
					width: 200
				}, {
					title: '有效期',
					key: "expireDate",
					width: 150
				},{
					title: '创建时间',
					key: "createTime",
					width: 200
				},{
					title: '来源',
					key: 'form',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					// pageSizeOptions: ['10','20', '50', '100', '500'],
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				refundVisible: false,
				refundData: {},
				isEdit: false,
				id: 0,
				pageType: 0
			}
		},
		created() {
			if (this.$router.currentRoute.value.path === '/coupon/yearCard/info') {
				this.pageType = 0;	// 年卡信息
			} else if (this.$router.currentRoute.value.path === '/coupon/yearCard/detailInfo') {
				this.pageType = 1;  // 年卡明细信息
			}
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.statusArr = this.pageType ?  "1,2,3,4,5,6,8" : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.status = this.searchData.status ? this.searchData.status : undefined;
				this.searchData.cardId = this.searchData.cardId ? this.searchData.cardId : undefined;
				if (this.searchData.isNeverExpire === -1) {
					this.searchData.isNeverExpire = undefined;
					this.time = [];
				}
				if (this.searchData.isNeverExpire === 1) {
					this.time = [];
				}
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('day').valueOf() / 1000;
					this.searchData.endTime = this.time[1].endOf('day').valueOf() / 1000;
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardInfoList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						type: 2,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true; 
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						if(ret.data.list && ret.data.list.length) {
							this.formState.organizationId = ret.data.list[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.organizationList = ret.data.list;
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
				
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					if(ret.data.list && ret.data.list.lenght) {
						this.formState.cinemaId = ret.data.list[0].id;
						this.getCardPolicyList(this.formState.cinemaId);
					}
					this.cinemaAllList = ret.data.list;
				}
			},
			async getCardPolicyList(cinemaId) {
				this.cardAllList = [];
				this.formState.cardId = 0;
				if (!cinemaId) return;
				let ret = await getCardPolicyList({
					page: 1,
					pageSize: 999999,
					type: 2,
					organizationId: this.formState.organizationId ? this.formState.organizationId : undefined,
					cinemaId: cinemaId ? cinemaId : undefined
				})
				if (ret.code === 200) {
					this.cardAllList = ret.data.list;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: "确定" + (item.isDisabled === 0 ? '禁用' : '启用') + '此卡吗？',
					onOk:async () => {
						this.loading = true;
						try {
							let ret;
							if (item.isDisabled === 0) {
								ret = await pullOffShelvesCardInfo({
									id: item.id
								})
							} else {
								ret = await pullOnShelvesCardInfo({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			delayEndTime(expireDate) {
				//  延期时间处理
				if(!expireDate) return '';
				if ((expireDate+'').length === 10) {
					expireDate = expireDate * 1000;
				}
				if (this.delayModelRef.setValue === 1) {
					return this.moment(expireDate).add(1, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 2) {
					return this.moment(expireDate).add(7, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 3) {
					return this.moment(expireDate).add(3, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 4) {
					return this.moment(expireDate).add(6, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 5) {
					return this.moment(expireDate).add(12, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 6) {
					return this.delayModelRef.specifiesTime.endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 7) {
					if (this.delayModelRef.delayDays) {
						return this.moment(expireDate).add(this.delayModelRef.delayDays, 'day').endOf('day').valueOf();
					} else {
						return this.moment(expireDate).endOf('day').valueOf();
					}
				} else {
					return this.moment(expireDate).endOf('day').valueOf();
				}
			},
			onDelay(item) {
				if (item) {
					this.selectedRowKeys = [];
					this.selectedListData = [];
				}
				this.setRecordData = item;
				this.setVisible = !this.setVisible;
			},
			onDelayCancel() {
				if(!this.visible) {
					this.delayModelRef = {};
				}
				this.setVisible = false;
			},
			onDelayOk() {
				// 延期
				this.$refs.delayForm.validateFields().then(async () => {
					if (!this.visible) {
						this.loading = true;
						try {
							let ret = await delayCardInfo({
								id: this.setRecordData.id,
								delayTime: parseInt(this.delayEndTime(this.setRecordData.expireDate) / 1000)
							});
							this.loading = false;
							if (ret.code === 200) {
								this.delayModelRef = {};
								this.setRecordData = {};
								this.$message.success('操作成功');
								this.setVisible = false;
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					} else {
						this.setVisible = false;
					}
				})
			},
			onBatchDelay() {
				let canBatchDelay = true;
				for (let i = 0; i < this.selectedListData.length;i++) {
					if (this.selectedListData[i].isNeverExpire === 1 && this.selectedListData[i].status !== 7) {
						canBatchDelay = false;
						break;
					}
				}
				if (!canBatchDelay) {
					return this.$message.warn('已选择的年卡中含有【永久有效】或【已退卡】，无法进行批量延期操作');
				}
				this.visible = true;
			},
			onBatchCancel() {
				this.delayModelRef = {};
				this.visible = false;
			},
			async onBatchDelayOk() {
				// 批量延期
				if (!this.delayModelRef.setValue) {
					return this.$message.warn('请设置延期时间');
				}
				let postData = this.selectedListData.map(item => {
					return {
						id: item.id,
						delayTime: item.isNeverExpire ? null : parseInt(this.delayEndTime(item.expireDate) / 1000)
					}
				})
				this.loading = true;
				try {
					let ret = await batchDelayCardInfo({
						userCards: postData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.delayModelRef = {};
						this.selectedRowKeys = [];
						this.selectedListData = [];
						this.$message.success('操作成功');
						this.visible = false;
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onRefund(item) {
				this.refundData = {
					id: item.id
				};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.refundData = {};
				this.refundVisible = false;
			},
			onRefundOk() {
				this.$refs.refundForm.validateFields().then(async ()=> {
					this.loading = true;
					try {
						let ret = await cancelCard({
							id: this.refundData.id,
							refundCardRemark: this.refundData.remark
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('退卡成功');
							this.onRefundCancel();
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onSelectChange(record, selected) {
				if (selected) {
					if (this.selectedRowKeys.length < 500) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.$message.warn('最大可选择数据不允许大于500条');
					}
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					let showWain = true;
					this.list.forEach(item => {
						if (this.selectedRowKeys.length < 500) {
							if (this.selectedRowKeys.indexOf(item.id) === -1 && item.status != 7 && item.isNeverExpire != 1) {
								this.selectedListData.push(JSON.parse(JSON.stringify(item)));
								this.selectedRowKeys.push(item.id);
							}
						} else {
							if (showWain) {
								showWain = false;
								this.$message.warn('最大可选择数据不允许大于500条');
							}
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onDeatil(item) {
				this.id = item.id;
				this.showModal = true;
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(() => {

				})
			},
			getCheckboxProps(record) {
				return {
					disabled: (record.status === 7 || record.isNeverExpire === 1),
					id: record.id.toString()
				}
			},
			disabledDate(current) {
				return current && current < this.moment().endOf('day');
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
